import * as client_hooks from '../../../src/app/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~4],
		"/(with-breadcrumbs)/about-us": [5,[2]],
		"/api/institutions-subscribe": [~20],
		"/api/subscribe": [~21],
		"/(with-breadcrumbs)/article-[slug]": [6,[2]],
		"/(with-breadcrumbs)/artist-wallet-authorization/[key]": [~8,[2]],
		"/(with-breadcrumbs)/artist-[id]-[name]/[...slug]": [~7,[2]],
		"/(with-breadcrumbs)/artists/[...slug]": [~9,[2]],
		"/artwork-[id]-[slug]/[[param1]]": [~22],
		"/(with-breadcrumbs)/artworks/[...slug]": [~10,[2]],
		"/auction-[id]": [~23],
		"/(with-breadcrumbs)/auctions/[...slug]": [~11,[2]],
		"/collector/artwork-[id]-[slug]/[[param1]]": [~24],
		"/(with-breadcrumbs)/contact-us": [~12,[2]],
		"/(with-breadcrumbs)/curator-[id]-[slug]/[...searchParams]": [~13,[2]],
		"/(with-breadcrumbs)/curators/[...slug]": [~14,[2]],
		"/(with-breadcrumbs)/faq": [15,[2]],
		"/(with-breadcrumbs)/news/[...slug]": [~16,[2]],
		"/(with-breadcrumbs)/privacy-policy": [~17,[2]],
		"/(with-breadcrumbs)/process": [~18,[2]],
		"/search/artists/[...slug]": [~25,[3]],
		"/search/artworks/[...slug]": [~26,[3]],
		"/(with-breadcrumbs)/terms-of-use": [~19,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';